<template>
  <v-dialog v-model="ModalEdit" :max-width="dekstop">
    <template v-slot:activator="{ on: modal, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            small
            fab
            text
            color="primary"
            v-bind="attrs"
            v-on="{ ...tooltip, ...modal }"
            @click="openModal()"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit Data</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Edit Misi Inspektorat</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <v-row class="mb-5">
            <v-col cols="12">
              <tiptap-vuetify
                v-model="item.profil_konten"
                :extensions="extensions"
                placeholder="Silahkan Diisi Dengan Kata Sambutan Kepla Badan Inspektorat ..."
                :card-props="{ outlined: true }"
              />
            </v-col>
          </v-row>

          <hr />
          <div class="text-right mt-5 pb-5">
            <v-btn
              v-if="btnLoading"
              small
              color="primary"
              depressed
              @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else small color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import refreshView from "@/store/profil/misi/viewMisi";

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  components: { TiptapVuetify },

  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.dekstop = "100%";
    }
  },

  data: () => ({
    session: "",
    dekstop: "80%",
    ModalEdit: false,
    btnLoading: true,

    item: {},

    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),

  methods: {
    async openModal() {
      this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
      this.getData();
      this.ModalEdit = true;
    },

    closeModal() {
      this.ModalEdit = false;
    },

    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "profil/misi")
        .then((res) => {
          this.item = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async edit() {
      this.btnLoading = false;

      const data = {
        profil_konten: this.item.profil_konten,
      };

      const url = process.env.VUE_APP_API_BASE + "profil/misi";
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.session.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          refreshView.commit("refreshData", true);
          refreshView.commit("alert", error.response.data.message);
          refreshView.commit("gagalAlert", true);
          refreshView.commit("berhasilAlert", false);
          refreshView.commit("success", error.response.data.success);
          console.log(error.response.status);
          this.btnLoading = true;
          this.closeModal();
        });
    },
  },
};
</script>
