<template>
  <div>
    <v-alert>
      <h3>Visi & Misi Inspektorat</h3>
    </v-alert>

    <div class="mb-5">
      <CVisi />
    </div>

    <div>
      <CMisi />
    </div>
  </div>
</template>

<script>
import CVisi from "@/components/Admin/profil/visimisi/visi/visi";
import CMisi from "@/components/Admin/profil/visimisi/misi/misi";

export default {
  components: {
    CVisi,
    CMisi
  },

  data: () => ({
    session: "",
  }),
};
</script>
